<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div>
      <CCard>
        <CCardBody>
          <div v-if="step === 0">
            <SearchList
              v-if="counter_updated.branch_id === null"
              :ph="$t('cloud.title.ph_select_branch')"
              :list="branch_list"
              :pageTotal="branch_page_total"
              @search="onSearchBranch"
              @focus="onFocusBranch"
              @select="onSelectBranch"
              @page="onPageBranch"
            />
            <CCard v-else>
              <CCardBody>
                <div class="my-2">Branch</div>
                <CWidgetIcon :header="branch_name" color="primary">
                  <CIcon name="cil-room" width="24"/>
                </CWidgetIcon>
                <CButton style="float:right;" color="danger" shape="pill" @click="onReset">Reset</CButton>
              </CCardBody>
            </CCard>
          </div>
          <div v-if="step === 1">
            <CInput
              :label="$t('cloud.title.name')"
              :placeholder="$t('cloud.title.name')"
              horizontal
              v-model="counter_updated.name"
            />
            <CInput
              v-if="counter"
              :label="$t('cloud.title.code')"
              :placeholder="$t('cloud.title.name')"
              horizontal
              plaintext
              :description="$t('cloud.title.code_desc')"
              v-model="sid"
            />
          </div>
        </CCardBody>
      </CCard>
    </div>
    <template #header>
      <h6 class="modal-title">{{ counter? $t('cloud.title.edit_something', {something: title}):$t('cloud.title.add_something', {something: title}) }}  {{ ` - ${$t('cloud.title.step_n_t', {n:+(step+1), t:2})}` }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step === 0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step === 0" @click="onNext" color="primary">{{$t('button.next')}}</CButton>
      <CButton v-if="step === 1" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step === 1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'

export default {
  name: 'BICounterForm',
  components: {
    SearchList
  },

  data() {
    return {
      show: false,

      branch_list: [],
      branch_search: null,
      branch_page_total: 1,
      branch_page_current: 1,

      title: 'Counter',
      counter: null,
      counter_updated: {
        id: null,
        name: null,
        branch_id: null
      },
      branch_name: null,
      sid: null,

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  methods: {
    open(counter, title) {
      this.onCancel();
      this.counter = counter || null;
      this.initialize();
      this.title = title || this.title;
      this.show = true;
    },
    initialize() {
      if (this.counter) {
        this.counter_updated = {
          id: this.counter.id,
          name: this.counter.name,
          branch_id: this.counter.branch_id
        };
        this.branch_name = this.counter.branch_name;
        this.sid = this.counter.sid;
      } else {
        this.counter_updated = {
          id: null,
          name: null,
          branch_id: null
        };
        this.branch_name = null;
        this.sid = null;
      }
    },
    onCancel() {
      this.show = false;
      this.counter = null;
      this.counter_updated = {
        id: null,
        name: null,
        branch_id: null
      };
      this.step = 0;

      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_current = 1;
      this.branch_page_total = 1;
    },
    onSubmit() {
      if (!this.counter_updated.branch_id) {
        this.notification = this.$t('cloud.title.validate.branch');
        this.dismissCountDown = 3;
        return;
      }
      if (!this.counter_updated.name) {
        this.notification = this.$t('cloud.title.validate.name');
        this.dismissCountDown = 3;
        return;
      }
      if (this.counter_updated.name.length > 64) {
        this.notification = this.$t('cloud.title.validate.long_name');
        this.dismissCountDown = 3;
        return;
      }

      this.$emit('payload', this.counter_updated);
      this.onCancel();
      this.show = false;
    },
    onNext() {
      if (!this.counter_updated.branch_id) {
        this.notification = this.$t('cloud.title.validate.branch');
        this.dismissCountDown = 3;
        return;
      }
      this.step = 1;
    },
    onPrev() {
      this.step = 0;
    },

    getBranches(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.branch_search,
        page: this.branch_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.branch_page_total = result.data.page.total;
          this.branch_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },

    onSearchBranch(text) {
      this.branch_search = text;
      this.getBranches();
    },
    onFocusBranch() {
      this.getBranches();
    },
    onPageBranch(p) {
      this.branch_page_current = p;
      this.getBranches();
    },
    onSelectBranch(id) {
      let store = this.branch_list.find(el => el.id === id);
      this.counter_updated.branch_id = store.branch_id;
      this.branch_name = store.name;
    },
    onReset() {
      this.counter_updated.branch_id = null;
      this.branch_name = null;
      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_total = 1;
      this.branch_page_current = 1;
    }
  }
}
</script>
