<template>
  <div>
    <StatFilter PERIOD="daily" :STORE_OPTS="branch_list" @query="onQueryBI" />
    <CRow v-if="load_done">
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.visitor_today')" :text="`${stats_today_visitor}`">
          <h4 :class="{'text-success':todayVisitIncrease >= 0,'text-danger':todayVisitIncrease<0}">
            <svgicon :name="todayVisitIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayVisitIncrease*todayVisitIncrease)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.visitor_today_around')" :text="`${stats_today_traffic}`">
          <h4 :class="{'text-success':todayTrafficIncrease >= 0,'text-danger':todayTrafficIncrease<0}">
            <svgicon :name="todayTrafficIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayTrafficIncrease*todayTrafficIncrease)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.visitor_visit_rate')" :text="`${visitRate}`">
          <h4 v-if="visitRateIncrease !== null" :class="{
            'text-success': visitRateIncrease > 0,
            'text-danger': visitRateIncrease < 0
          }">
            <svgicon :name="visitRateIncrease > 0 ? 'arrow_drop_up':(visitRateIncrease < 0 ? 'arrow_drop_down':'')" width="23" height="23" />
            <span>{{visitRateIncrease}}%</span>
          </h4>
          <h4 v-else>-</h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.visitor_busiest_time')" :text="stats_busiest_hour">
          <h4 class="text-secondary">
            {{$t('cloud.title.lastweek')}} : Wed 15:00
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="12" lg="6">
        <CardBarCharts
          :title="$t('cloud.title.traffic_around')"
          :stats="[stats_bi_traffic_around, stats_bi_traffic_around_yesterday, stats_bi_traffic_around_lastweek]"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.day_yesterday'), $t('cloud.title.day_lastweek')]"
          :colors="['#358ec4', '#f0c33c', '#e035b2']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardBarCharts
          :title="$t('cloud.title.customer_visit')"
          :stats="[stats_bi_customer_visit, stats_bi_customer_visit_yesterday, stats_bi_customer_visit_lastweek]"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.day_yesterday'), $t('cloud.title.day_lastweek')]"
          :colors="['#358ec4', '#f0c33c', '#e035b2']"
        />
      </CCol>
      <CCol md="12" lg="6" class="d-lg-none">
        <CardLineCharts
          :title="$t('cloud.title.visitor_visit_rate')"
          :stats="visitRates"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.day_yesterday'), $t('cloud.title.day_lastweek')]"
          :colors="['#358ec4', '#f0c33c', '#e035b2']"
          :y_unit="'%'"
        />
      </CCol>
      <CCol md="12" class="d-md-down-none">
        <CardLineCharts
          :title="$t('cloud.title.visitor_visit_rate')"
          :stats="visitRates"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.day_yesterday'), $t('cloud.title.day_lastweek')]"
          :colors="['#358ec4', '#f0c33c', '#e035b2']"
          :y_unit="'%'"
          :aspect_ratio="4"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardBarCharts from '@/components/cards/BarCharts.vue'
import CardLineCharts from '@/components/cards/LineCharts.vue'

export default {
  name: 'RetailVisitCountingAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardBarCharts,
    CardLineCharts
  },

  data() {
    return {
      load_done: false,
      loading: {
        branch: true,
        traffic: true,
        visit: true
      },
      filters: null,
      branch_list: [],
      stats_bi_traffic_around: null,
      stats_bi_traffic_around_yesterday: null,
      stats_bi_traffic_around_lastweek: null,
      stats_bi_customer_visit: null,
      stats_bi_customer_visit_yesterday: null,
      stats_bi_customer_visit_lastweek: null,
      stats_today_visitor: 0,
      stats_today_visitor_yesterday: 0,
      stats_today_traffic: 0,
      stats_today_traffic_yesterday: 0,
      stats_busiest_hour: 'Tue 14:00'
    }
  },
  computed: {
    visitRate() {
      if (this.stats_today_traffic === 0) {
        return '-';
      }
      return `${Math.round(this.stats_today_visitor * 100 * 10 / this.stats_today_traffic) / 10}%`;
    },
    todayVisitIncrease() {
      return (this.stats_today_visitor - this.stats_today_visitor_yesterday);
    },
    todayTrafficIncrease() {
      return (this.stats_today_traffic - this.stats_today_traffic_yesterday);
    },
    visitRateIncrease() {
      if (this.stats_today_traffic === 0 || this.stats_today_traffic_yesterday === 0) {
        return null;
      }
      const y_inc = Math.round(this.stats_today_visitor_yesterday * 100 * 100 / this.stats_today_traffic_yesterday) / 100;
      const t_inc = Math.round(this.stats_today_visitor * 100 * 100 / this.stats_today_traffic) / 100;
      let val = t_inc - y_inc;
      if (val < 0) val = val * (-1);
      val = Math.round(val * 100);
      val = val / 100;
      return val;
    },
    visitRates() {
      const today_rates = [];
      const yesterday_rates = [];
      const lastweek_rates = [];
      for (const i in this.stats_bi_traffic_around) {
        let rate = null;
        if (this.stats_bi_traffic_around[i].value) {
          rate = Math.round(this.stats_bi_customer_visit[i].value * 100 * 10 / this.stats_bi_traffic_around[i].value) / 10;
        }
        today_rates.push({
          value: rate,
          x: this.stats_bi_traffic_around[i].x
        });

        rate = null;
        if (this.stats_bi_traffic_around_yesterday[i].value) {
          rate = Math.round(this.stats_bi_customer_visit_yesterday[i].value * 100 * 10 / this.stats_bi_traffic_around_yesterday[i].value) / 10;
        }
        yesterday_rates.push({
          value: rate,
          x: this.stats_bi_traffic_around_yesterday[i].x
        });

        rate = null;
        if (this.stats_bi_traffic_around_lastweek[i].value) {
          rate = Math.round(this.stats_bi_customer_visit_lastweek[i].value * 100 * 10 / this.stats_bi_traffic_around_lastweek[i].value) / 10;
        }
        lastweek_rates.push({
          value: rate,
          x: this.stats_bi_traffic_around_lastweek[i].x
        });
      }
      return [today_rates, yesterday_rates, lastweek_rates];
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getBranchList();
      // this.getQueueTransaction();
      // this.onQueryBI();
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 500, // FIXME. 난중에 하자...
        page: 1,
        opt: 'branch',
        geo_info: 0
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.branch = true;
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.branch = false;
          this.checkLoading();
        })
    },
    onQueryBI(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.statTrafficAround();
        this.statCustomerVisit();
        this.statBusiestHour();
      }
    },
    buildBIQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);

          if (date_before) {
            const delta = date_before * 24 * 60 * 60 * 1000;
            params.sts = params.sts - delta;
            params.ets = params.ets - delta;
          }
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statTrafficAround() {
      const query_string = this.buildBIQs();
      const query_string_yesterday = this.buildBIQs(1);
      const query_string_lastweek = this.buildBIQs(7);
      let loading_today = true;
      let loading_yesterday = true;
      let loading_lastweek = true;
      let loading_sub1 = true;
      let loading_sub2 = true;

      this.loading.traffic = true;
      axios.get(`/api/bi/statistics/traffic/${query_string}`)
        .then(result => {
          this.stats_bi_traffic_around = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_today = false;
          if (!loading_yesterday && !loading_lastweek && !loading_sub1 && !loading_sub2) {
            this.loading.traffic = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/traffic/${query_string_yesterday}`)
        .then(result => {
          this.stats_bi_traffic_around_yesterday = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_yesterday = false;
          if (!loading_today && !loading_lastweek && !loading_sub1 && !loading_sub2) {
            this.loading.traffic = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/traffic/${query_string_lastweek}`)
        .then(result => {
          this.stats_bi_traffic_around_lastweek = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_lastweek = false;
          if (!loading_today && !loading_yesterday && !loading_sub1 && !loading_sub2) {
            this.loading.traffic = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/traffic/${query_string}&is_today=1`)
        .then(result => {
          this.stats_today_traffic = result.data.total_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_sub1 = false;
          if (!loading_today && !loading_yesterday && !loading_lastweek && !loading_sub2) {
            this.loading.traffic = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/traffic/${query_string_yesterday}&is_today=1`)
        .then(result => {
          this.stats_today_traffic_yesterday = result.data.total_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_sub2 = false;
          if (!loading_today && !loading_yesterday && !loading_lastweek && !loading_sub1) {
            this.loading.traffic = false;
            this.checkLoading();
          }
        })
    },
    statCustomerVisit() {
      const query_string = this.buildBIQs();
      const query_string_yesterday = this.buildBIQs(1);
      const query_string_lastweek = this.buildBIQs(7);
      let loading_today = true;
      let loading_yesterday = true;
      let loading_lastweek = true;
      let loading_sub1 = true;
      let loading_sub2 = true;

      this.loading.visit = true;
      axios.get(`/api/bi/statistics/customer-visit/${query_string}`)
        .then(result => {
          this.stats_bi_customer_visit = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_today = false;
          if (!loading_yesterday && !loading_lastweek && !loading_sub1 && !loading_sub2) {
            this.loading.visit = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/customer-visit/${query_string_yesterday}`)
        .then(result => {
          this.stats_bi_customer_visit_yesterday = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_yesterday = false;
          if (!loading_today && !loading_lastweek && !loading_sub1 && !loading_sub2) {
            this.loading.visit = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/customer-visit/${query_string_lastweek}`)
        .then(result => {
          this.stats_bi_customer_visit_lastweek = result.data.map(el => {
            const val = el.delta + el.bias_pos + el.bias_neg;
            const created_at = el.created_at
            return {value: val, x: created_at}
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_lastweek = false;
          if (!loading_today && !loading_yesterday && !loading_sub1 && !loading_sub2) {
            this.loading.visit = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/customer-visit/${query_string}&is_today=1`)
        .then(result => {
          this.stats_today_visitor = result.data.total_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_sub1 = false;
          if (!loading_today && !loading_yesterday && !loading_lastweek && !loading_sub2) {
            this.loading.visit = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/customer-visit/${query_string_yesterday}&is_today=1`)
        .then(result => {
          this.stats_today_visitor_yesterday = result.data.total_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_sub2 = false;
          if (!loading_today && !loading_yesterday && !loading_lastweek && !loading_sub1) {
            this.loading.visit = false;
            this.checkLoading();
          }
        })
    },
    statBusiestHour() {
      
    },
    checkLoading() {
      if (!this.loading.branch && !this.loading.traffic && !this.loading.visit) {
        setTimeout(() => {
          this.load_done = true;
        }, 100);
      }
    }
  }
}
</script>